import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function EventCompliancesRegistration() {
  /* Slider */
  // const EventCompRegSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Event-Based Compliances for Companies`,
  //     content: `India's Best E-Filing Event-Based Compliances for Companies`,
  //     image: "/imgs/business/dsc1-lib.png",
  //     alt_tag:
  //       "Best Online Event-Based Compliances for Companies Filing Service Near Bangalore",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 80,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Event-Based Compliances for Companies`,
  //     content: `Best E-filing Event-Based Compliances for Companies in Hosur`,
  //     image: "/imgs/business/llp.png",
  //     alt_tag:
  //       "Best Online Event-Based Compliances for Companies Filing Service in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `E-Filings Event-Based Compliances for Companies `,
  //     content: `Event-Based Compliances for Companies`,
  //     image: "/imgs/registration/msme/ms-me-reg.png",
  //     alt_tag:
  //       "Best Online E-Filings Event-Based Compliances for Companies in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Event Based Compliances Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `4999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const EventCompAboutData = {
    header: `Event-Based Compliances for Companies`,
    sub_title: `One Of The Best Event Based Compliances filings for Companies`,
    content_paragraph: [
      `Event-based Business In India, ROC compliance filing online
      is required in addition to the organization's regular and
      mandated annual and periodical compliances with the ROC and
      other relevant administrative bodies. TODAYFILINGS will be your legal
      ally as you protect your company!`,

      // `The procedure appears to be the step that matters the most
      // when registering a new firm. A private limited company must
      // do a number of responsibilities on various occasions.`,

      // `Once it is established in accordance with the provisions of the
      // Companies Act, 2013, nevertheless. To be compliant with the
      // law, look into several event-based company compliances.`,
    ],
  };
  /* Scroll Nav Data */
  const EventCompScrollId = [
    {
      id: `#forms`,
      heading: `Form`,
    },
    {
      id: ``,
      heading: `Event based compliances`,
    },
    {
      id: `#overview`,
      heading: `Overview`,
    },
  ];

  /* Content Form Component Data */
  const EventCompCfData = {
    id: 'overview',
    // mt_div: "mt-5",
    // mt_img: "mt-5",
    header: `Event-Based Compliance by various organizations in
    accordance with the Companies Act of 2013.`,
    content: `The GST Act, 2017, Sections 22 to 30, deal with the
    registration process for each supplier of goods and
    services. Note regarding GST registration beginning on
    April 1, 2019.`,
    body: [
      {
        head: '',
        points: `The well-known law practise with its headquarters in
        Chennai has a large staff of knowledgeable and
        experienced corporate attorneys, company secretaries,
        chartered accountants, intellectual property
        attorneys, and other legal experts. This specific
        section focuses only on the numerous event-based
        compliances by distinct business kinds.`,
        icon: false,
      },
      {
        head: '',
        points: `The majority of the following unforeseen or contingent
        tasks, events, or business activities may include
        reporting or compliances depending on a company's kind
        and its specific business and service operations.`,
        icon: false,
      },
      {
        head: '',
        points: `Acquiring DSC's, DIN's, DPIN's, etc.`,
        icon: true,
      },
      {
        head: '',
        points: `Maintaining and Keeping Up with Statutory Records and
        Registers.`,
        icon: true,
      },
      {
        points: `Creating resolutions and minutes for board, committee,
        and general meetings.`,
        icon: true,
      },
      {
        points: `Changes to the MOA, AOA, or LLP Agreement company's
        name or address.`,
        icon: true,
      },
      {
        points: `The company's authorized capital may be modified shares of a company are issued, allotted, or
        transferred.`,
        icon: true,
      },
      {
        points: `Expansion or Service/Business Diversification.`,
        icon: true,
      },
      {
        points: ` Business restructuring or business transformation.`,
        icon: true,
      },
      {
        points: `Getting the Right Arrangement with Related Parties.`,
        icon: true,
      },
      {
        points: `Obtaining or updating the company's necessary licenses.`,
        icon: true,
      },
      {
        points: `Registration, maintenance, and protection of the
        aforementioned intellectual property rights.`,
        icon: true,
      },
      {
        points: `Event-based compliances with numerous referred
        authorities, laws, and regulatory organizations.`,
        icon: true,
      },
      {
        points: `Any compliances that are prudent or advisable in light
        of the Secretarial Standards I and II.`,
        icon: true,
      },
    ],
  };

  var FAQ_data = [
    {
      header: 'What makes 12A and 12AA different from one another?',
      body: [
        {
          content: `In addition to the regular and necessary annual and periodic
          compliances made by the company with the ROC and other relevant
          administrative bodies, event-based compliances are compliances
          that must be made.`,
          icon: false,
        },
      ],
    },

    {
      header: 'What kinds of compliances are event-based?',
      body: [
        {
          content: `Annual Compliances`,
          icon: false,
        },
        {
          content: `Event-Based Compliance`,
          icon: false,
        },
      ],
    },
    {
      header: 'Who should I inform about any changes?',
      body: [
        {
          content: `Every modification to any registered information related to the
          company must be properly reported to the relevant ROC and other
          statutory or regulatory bodies.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is it necessary to mention CSR policy?',
      body: [
        {
          content: `Yes, CSR must be included. If there is a change to the company's
          registered office, E-Form INC-22 must be submitted to the ROC.`,
          icon: false,
        },
      ],
    },
    {
      header: 'When should E-Form MGT-14 be filed?',
      body: [
        {
          content: `By submitting E-Form MGT-14, a business must file a number of
          agreements and resolutions with the ROC.`,
          icon: false,
        },
      ],
    },
    {
      header: ' Who should sign the paperwork while it is being filed?',
      body: [
        {
          content: `Chief Financial Officer`,
          icon: true,
        },
        {
          content: `Chief Executive Officer, or Managing Director`,
          icon: true,
        },
        {
          content: `Auditor Chairman of the Audit Committee`,
          icon: true,
        },
      ],
    },
    {
      header: 'Is the online payment secured?',
      body: [
        {
          content: `All the monetary transactions performed on online platform are secured with SSL System 
          Protocol. We encrypt the customer information such as credit card and bank account details, before these 
          are transmitted anywhere. We adhere to PCI DSS for data security standards for payment processing.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How can I be sure that my documents are safe?',
      body: [
        {
          content: `We believe it is our responsibility to protect our customer information from 
          unauthorized access. We have put systems and processes in place to make sure that the customer information 
          is safe with us during its storage and transfer between in house and third party servers. We continuously 
          test our systems and processes for security breach and vulnerabilities are identified and fixed at a regular 
          basis.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Event-Based Compliances for Companies'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={EventCompRegSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={EventCompAboutData} />

          <Counter />

          <ScrollNav scroll_data={EventCompScrollId} />
          <section id='forms' className='mb-0'>
            <div className='container'>
              <header className='section-header'>
                <h2>
                  Compliances with Event-based ROCs aimed at a Private Limited
                  Company
                  <div className='bar'></div>
                </h2>
              </header>
              <p>
                A private limited business must file a number of compliance
                documents and paperwork to inform the ROC of any modifications,
                alternations, or changes done inside the company. The applicable
                form to be filed is listed below along with some of the
                event-based tractability.
              </p>
              <div className='table-responsive'>
                <table className=' table table-hover table-bordered table-striped'>
                  <thead className='text-center'>
                    <tr className='bg-info text-white '>
                      <td>E-Form</td>
                      <td>Various Compliance Types</td>
                      <td>Legal Requirement</td>
                    </tr>
                  </thead>
                  <tbody className='text-center'>
                    <tr>
                      <td>
                        <strong>DIR-12</strong>
                      </td>
                      <td>Transformation in Director</td>
                      <td>Section: 149</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>SH-7</strong>
                      </td>
                      <td>Change in authorized Share Capital</td>
                      <td>Section: 61 and 64</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>PAS-3, MGT-14</strong>
                      </td>
                      <td>Return of Allocation</td>
                      <td>Section: 62</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>CHG-4</strong>
                      </td>
                      <td>Filing of Satisfaction of Charge</td>
                      <td>Section: 82</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>CHG-1</strong>
                      </td>
                      <td>Modification and creation of Charge</td>
                      <td>Section: 77</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>INC-22</strong>
                      </td>
                      <td>
                        Fluctuating of Registered Office inside the same city,
                        village or town without modification in the jurisdiction
                        of ROC
                      </td>
                      <td>Section: 12</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>ADT-3</strong>
                      </td>
                      <td>Resignation of authorized Statutory Auditor</td>
                      <td>Section: 140</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>INC-23, INC-28, MGT-14 And INC-22</strong>
                      </td>
                      <td>
                        Fluctuating of Registered Office exterior of the same
                        city, village or town with the change in the
                        jurisdiction of ROC
                      </td>
                      <td>Section: 12 and 13</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>DPT-3</strong>
                      </td>
                      <td>Return of Deposits with the Company</td>
                      <td>Section: 73</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Form MSME</strong>
                      </td>
                      <td>Return for Delay in prescribed payments to MSMEs</td>
                      <td>Section: 405</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>AOC-5</strong>
                      </td>
                      <td>
                        Additional place other than the registered office where
                        the books or reports of accounts and statutory registers
                        are being maintained
                      </td>
                      <td>Section: 128</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>MGT-14</strong>
                      </td>
                      <td>
                        Filing of Resolutions and Agreements to the concerned
                        ROC
                      </td>
                      <td>Section: 117 and Section 179</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>BEN-2</strong>
                      </td>
                      <td>
                        Disclosure of Substantial Beneficial Ownership or SBO
                      </td>
                      <td>Section: 90</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <ContentForm CFSection_data={EventCompCfData} />
          <section style={{ marginTop: 50 }}></section>
          <Cta />

          <Guidance />

          <FAQAccord items={FAQ_data} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
